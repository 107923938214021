.terms-conditions-container {
    background: #fff;
    color: #333;
    padding: 50px 20px;
    margin: 20px auto;
    max-width: 800px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .terms-conditions-container h2 {
    color: #1c2237;
    margin-bottom: 20px;
  }
  
  .terms-conditions-container h3 {
    color: #1c2237;
    margin-top: 20px;
  }
  
  .terms-conditions-container p,
  .terms-conditions-container ul li { /* If you use lists */
    font-size: 1rem;
    line-height: 1.6;
  }
  