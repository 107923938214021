/* HeroSection.css */
.hero-container {
  background: linear-gradient(to right, #0062e6, #33aeff);
  color: #fff;
  padding: 100px 20px;
  text-align: center;
  transition: all 0.3s ease;
}

.hero-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  animation: fadeInUp 1s ease-out;
}

.hero-container p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  animation: fadeInUp 1.2s ease-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
