/* App.css */

/* Global Styles */
.app-container {
  font-family: 'Poppins', sans-serif;
  color: #333;
  overflow-x: hidden;
}

.section-container > div {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
}

/* Smooth Scroll */
html {
  scroll-behavior: smooth;
}

/* Navigation Styles */
.navbar {
  background-color: #ffffff;
  padding: 1rem 0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-container {
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  color: #0a192f;
  text-decoration: none;
  font-size: 1.5rem;
}

.nav-menu {
  list-style: none;
  display: flex;
  padding: 0;
}

.nav-item {
  margin: 0 10px;
}

.nav-links {
  color: #0a192f;
  text-decoration: none;
  font-size: 1rem;
}

.nav-links:hover {
  color: #e63946;
}

/* Hero Section Styles */
.hero-container {
  background: linear-gradient(to right, #0062e6, #33aeff);
  color: #fff;
  text-align: center;
  padding: 50px 20px;
}

.hero-container h1 {
  font-size: 2.5rem;
}

.hero-container p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

/* Services Section Styles */
.services-container {
  background-color: #f5f5f5;
  padding: 20px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.service {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.service h3 {
  margin-bottom: 15px;
}

/* About Section Styles */
.about-container {
  background-color: #fff;
  padding: 20px;
}

/* Privacy Policy Styles */
.privacy-policy-container {
  background-color: #fff;
  color: #333;
  text-align: left;
  padding: 50px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Contact Section Styles */
.contact-container {
  background-color: #f5f5f5;
  padding: 20px;
}

.contact-container form {
  max-width: 500px;
  margin: auto;
}

/* Footer Styles */
.footer-container {
  background-color: #0a192f;
  color: #fff;
  text-align: center;
  padding: 20px 0;
}

.footer-link {
  color: #fff;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

/* Media Queries for Responsive Design */
@media screen and (max-width: 768px) {
  .navbar-container,
  .section-container > div,
  .hero-container,
  .services-container,
  .about-container,
  .privacy-policy-container,
  .contact-container {
    padding: 0 15px;
  }

  .nav-menu {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .nav-item {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}
