/* Footer.css */
.footer-container {
  background-color: #262626; /* Dark background for contrast */
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  margin-bottom: 15px;
}

.footer-link {
  color: #f2f2f2;
  text-decoration: none;
  margin: 0 10px;
  padding: 5px;
  border-radius: 5px;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #4db8ff; /* Light blue for hover state */
}

.footer-bottom {
  margin-top: 20px;
  font-size: 0.8rem;
}
