/* Navigation.css */

/* Existing styles remain the same, only adding new styles below */

.menu-icon {
  display: none;
  font-size: 2rem;
  background: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    color: #1c2237; /* Ensure the icon is visible */
  }

  .nav-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 60%; /* Adjust width as per content requirement */
    max-width: 300px; /* Optional: in case of very small screens */
    height: 100vh;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); /* Shadow on the opposite side */
  }

  .nav-menu.active {
    transform: translateX(0);
  }

  .nav-item {
    margin-bottom: 20px;
  }

  .nav-links {
    font-size: 1.5rem;
  }
}
