/* PrivacyPolicy.css */
.privacy-policy-container {
  background: #fff;
  color: #333;
  padding: 50px 20px;
  margin: 20px auto;
  max-width: 800px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.privacy-policy-container h2 {
  color: #1c2237;
  margin-bottom: 20px;
}

.privacy-policy-container h3 {
  color: #1c2237;
  margin-top: 20px;
}

.privacy-policy-container p {
  font-size: 1rem;
  line-height: 1.6;
}
