/* Services.css */
.services-container {
  background: #f5f5f5; /* Light gray background */
  padding: 50px 20px;
  text-align: center;
}

.services-container h2 {
  color: #0d0d0d;
  margin-bottom: 30px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: auto;
}

.service {
  background: white;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.service h3 {
  color: #1c2237;
  margin-bottom: 15px;
}

.service p {
  color: #333;
  font-size: 1rem;
}
