/* Contact.css */
.contact-container {
  background: #f5f5f5; /* Light gray background */
  padding: 50px 20px;
  text-align: center;
}

.contact-container h2 {
  color: #0d0d0d;
  margin-bottom: 20px;
}

.contact-container p {
  color: #333;
  font-size: 1rem;
  margin-bottom: 10px;
}

.contact-info a {
  color: #1c2237;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.contact-info .whatsapp-link {
  background-color: #080503; /* WhatsApp green */
  color: #fff;
  padding: 10px 20px;
  border-radius: 2px;
  text-decoration: none;
}

.contact-info .whatsapp-link:hover {
  background-color: #128c7e; /* Darker WhatsApp green on hover */
}
